
import { Component, Vue } from 'vue-property-decorator'
import { Login } from '../../types/user'

@Component({
  name: 'LoginSSOXz'
})
export default class extends Vue {
  get projectType () {
    return this.$route.query.projectType || ''
  }

  created () {
    this.login()
  }

  login () {
    let api = this.$apis.user.ssoLogin
    // 徐州园博园
    let errUrl = 'https://zg.xzyby.cn/sso/oauth2.0/authorize?response_type=code&client_id=WisdomMaintenanceOAuth&redirect_uri=https%3A%2F%2Fipark.gintell.cn%2F%23%2FloginSSOXz'
    // 无锡瑞景
    if (this.$route.name === 'loginSSORj') {
      api = this.$apis.user.ssoLoginRj
      errUrl = 'http://36.137.74.233:8085/login?redirect=%2Findex'
    }
    if (this.$route.name === 'loginSSOLk') {
      api = this.$apis.common.tuYaYunUserLogin
      errUrl = 'https://ipark.gintell.cn'
    }
    console.log('当前路由名称', this.$route.name)
    console.log('当前路由参数', this.$route.query)
    this.$axios.post<Login>(api, this.$route.query).then(res => {
      window.sessionStorage.setItem('token', res.token)
      window.sessionStorage.setItem('customerCode', res.customer.customerCode)
      window.sessionStorage.setItem('isImgHtml', res.customer.isImgHtml)
      window.sessionStorage.setItem('userInfo', JSON.stringify(res.user))
      this.routeJump(res.customer.isImgHtml)
    }).catch((err) => {
      if (err) {
        this.$confirm(err, '提示').then(() => {
          window.location.href = errUrl
        }).catch(() => {
          console.log('取消了')
        })
      }
    })
  }

  // 路由跳转
  routeJump (isImgHtml: string) {
    const queryInfo = this.projectType !== '' ? { projectType: this.projectType } : {}
    if (isImgHtml === '2') {
      window.location.href = window.location.origin + '/integrated/'
    } else {
      this.$router.push({ path: '/', query: queryInfo })
    }
  }
}
